import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faFolder } from "@fortawesome/free-regular-svg-icons"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import SEO from "../components/seo"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const options = {
   renderNode: {
     [BLOCKS.HEADING_2]: (node, Children) => (
       <h2>{Children}</h2>
     ),
     [BLOCKS.EMBEDDED_ASSET]: node => (
       <GatsbyImage
       image={node.data.target.gatsbyImageData}
       alt={
         node.data.target.description
          ? node.data.target.description
          : node.data.target.title
       }
       />
     ),
   },
   renderText: text => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

const Blogpost = ({ data, pageContext, location }) => (
  <Layout>
    <SEO  pagetitle={data.contentfulBlogPost.title} 
          pagedesc={`${documentToPlainTextString(
            JSON.parse(data.contentfulBlogPost.content.raw)
          ).slice(0, 70)}...`}
          pagepath={location.pathname}
          blogimg={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
          pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
          pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
    />
    <div className="eyecatch">
    <figure>
      <GatsbyImage
      image={data.contentfulBlogPost.eyecatch.gatsbyImageData}
      alt={data.contentfulBlogPost.eyecatch.description}
      />
    </figure>
    </div>
    <section className="area-article">
    <div className="container">
      <h2 className="article-bar">{ data.contentfulBlogPost.title }</h2>
      <aside className="info">
        <time dateTime={data.contentfulBlogPost.publishDate}>
            <FontAwesomeIcon icon={faCalendarAlt} />
            {data.contentfulBlogPost.publishDateJP}
        </time>
        <div className="cat">
          <FontAwesomeIcon icon={faFolder} />
          <ul>
            {data.contentfulBlogPost.category.map(cat => (
              <li className={cat.categorySlug} key={cat.id}>
                <Link to={`/cat/${cat.categorySlug}/`}>{cat.category}</Link>
              </li>
            ))}
          </ul>
        </div>
      </aside>
      <div className="postbody">
        {renderRichText(data.contentfulBlogPost.content, options)}
      </div>
      <ul className="postlink">
        {pageContext.next && (
        <li className="prev">
          <Link to={`/blog/post/${pageContext.next.slug}/`} rel="prev">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{pageContext.next.title}</span>
          </Link>
        </li>
        )}
        {pageContext.previous && (
        <li className="next">
          <Link to={`/blog/post/${pageContext.previous.slug}/`} rel="next">
            <span>{pageContext.previous.title}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </li>
        )}
      </ul>
    </div>
   </section>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
      publishDate
      category {
        category
        categorySlug
        id
      }
      eyecatch {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
        description
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: CONSTRAINED, width: 700)
            title
            description
          }
        }
      }
    }
  }
`



export default Blogpost
